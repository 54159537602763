import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import logo from './logo.svg';
import './App.scss';

// import paths
import Main from "./components/pages/main";
import Loan from "./components/pages/Loan";
import ConfirmLoan from "./components/pages/ConfirmLoan";
import LoanDisbursed from "./components/pages/LoanDisbursed";
import SupportCustomer from "./components/pages/customer_support";
// import ConfirmLoan from "./components/pages/ConfirmLoan";
import OTP from "./components/pages/otp";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Main} exact />
        <Route path="/loan" component={Loan} exact />
        <Route path="/otp" component={OTP} exact />
        <Route path="/confirm_loan" component={ConfirmLoan} exact />
        <Route path="/loan_disbursed" component={LoanDisbursed} exact />
        <Route path="/customersupport" component={SupportCustomer} exact />
        <Route path="/" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
