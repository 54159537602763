import React from "react";
// import { Link, Redirect, useHistory, withRouter } from 'react-router-dom';
// import AssuredLogo from "../../images/assured_logo.png"
import Livechat from "../../components/layout/Livechat"
// import {main} from "../../components/pages/main"


function Main() {






    return (
        <>
            <Livechat />


        </>
    );
}

export default Main;